import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/Login/Login';
import Register from './components/Login/Register';
import Home from './components/Dash/Home';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import ClassManager from './components/Classes/ClassManager';
import Reports from './components/Reports/Reports';
import ProtectedRoute from './components/ProtectedRoute';
import AdminProtectedRoute from './routes/AdminProtectedRoutes'; // Check this path
import Navbar from './components/Navbar';
import LandingPage from './components/Landing/LandingPage';
import './App.css';
import TermManager from './components/Terms/TermManager';
import { ThemeProvider } from './context/ThemeContext';
import ContactForm from './components/Login/ContactForm';
import TagManager from 'react-gtm-module';
import Feedback from './components/feedback/Feedback';
import { isAuthenticated } from "./auth";
import NewsletterEditor from './components/newsletter/NewsLetterEditor'; // Check this path

const AppContent = () => {
    const location = useLocation();

    React.useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                page: location.pathname,
            },
        });
    }, [location]);

    return (
        <>
            <Navbar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/dash" element={<ProtectedRoute element={<Home />} />} />
                    <Route path="/classes" element={<ProtectedRoute element={<ClassManager />} />} />
                    <Route path="/reports" element={<ProtectedRoute element={<Reports />} />} />
                    <Route path="/terms" element={<ProtectedRoute element={<TermManager />} />} />
                    <Route path="/contact" element={<ContactForm />} />
                    {/* Admin Protected Routes */}
                    <Route path="/newsletter" element={<AdminProtectedRoute element={<NewsletterEditor />} />} />
                </Routes>
            </div>
            {isAuthenticated() && <Feedback />}
        </>
    );
};

const App = () => {
    React.useEffect(() => {
        TagManager.initialize({ gtmId: 'G-00N0B37RSG' });
    }, []);

    return (
        <ThemeProvider>
            <React.StrictMode>
                <Router>
                    <AppContent />
                </Router>
            </React.StrictMode>
        </ThemeProvider>
    );
};

export default App;
