import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom'; // Updated import
import '../../css/Login.css'; // Reuse the CSS file for Login

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams(); // Extract token from path parameter
    const navigate = useNavigate();

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/reset-password'
            });
        }
    }, []);

    const handleResetPassword = async (e) => {
        e.preventDefault();

        // Check if token is present
        if (!token) {
            setMessage('Invalid or missing reset token');
            return;
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            // Send reset password request with token and new password
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/reset-password`, {
                token, // Include the token in the request payload
                password
            });

            // Set success message and navigate to login
            setMessage('Password has been reset successfully. Redirecting to login...');
            setTimeout(() => {
                navigate('/login');
            }, 2000); // Delay before redirecting to login
        } catch (error) {
            console.error('Reset password error:', error);
            // Set error message based on server response
            if (error.response && error.response.data) {
                setMessage(error.response.data.message || 'Error resetting password');
            } else {
                setMessage('Error resetting password');
            }
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2 className="login-title">Reset Password</h2>
                <form onSubmit={handleResetPassword} className="login-form">
                    <input
                        type="password"
                        placeholder="New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="login-input"
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="login-input"
                    />
                    <button type="submit" className="login-button">Reset Password</button>
                </form>
                {message && <p className="reset-message">{message}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;
