import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/ClassManager.css';


const ClassManager = () => {
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [editing, setEditing] = useState(false);
    const [newStudent, setNewStudent] = useState({ firstName: '', lastName: '' });
    const [creatingNewClass, setCreatingNewClass] = useState(false);
    const [newClassName, setNewClassName] = useState('');
    const [newClassRoll, setNewClassRoll] = useState('');
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [deletedClasses, setDeletedClasses] = useState([]);
    const [showDeletedClasses, setShowDeletedClasses] = useState(false);
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user'));


    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/classes'
            });
        }
    }, []);

    useEffect(() => {
        console.log('NEW CLASS PRESSED ??', creatingNewClass)
    }, [creatingNewClass]);

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/classes`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setClasses(data);
                } else {
                    console.error('Failed to fetch classes');
                }
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        const fetchDeletedClasses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/classes/deleted`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setDeletedClasses(data);
                } else {
                    console.error('Failed to fetch deleted classes');
                }
            } catch (error) {
                console.error('Error fetching deleted classes:', error);
            }
        };

        fetchClasses();
        fetchDeletedClasses();
    }, [token]);

    const handleClassClick = (classItem) => {
        setSelectedClass(classItem);
        setEditing(false); // Reset editing state when selecting a new class
    };

    const handleClassNameChange = (e) => {
        setSelectedClass({ ...selectedClass, className: e.target.value });
    };

    const handleStudentChange = (e, index, field) => {
        const updatedStudents = [...selectedClass.role];
        updatedStudents[index][field] = e.target.value;
        setSelectedClass({ ...selectedClass, role: updatedStudents });
    };

    const handleNewStudentChange = (e) => {
        const { name, value } = e.target;
        setNewStudent({ ...newStudent, [name]: value });
    };

    const addNewStudent = () => {
        setSelectedClass({
            ...selectedClass,
            role: [...selectedClass.role, { ...newStudent }]
        });
        setNewStudent({ firstName: '', lastName: '' });
    };

    const removeStudent = (index) => {
        const updatedStudents = selectedClass.role.filter((_, i) => i !== index);
        setSelectedClass({ ...selectedClass, role: updatedStudents });
    };

    const handleLinkClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/linkClass/link/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const updatedUser = await response.json();
                localStorage.setItem('user', JSON.stringify(updatedUser));
                alert('Class linked successfully!');
                window.location.reload(); // Reload to update the UI
            } else {
                alert('Failed to link class.');
            }
        } catch (error) {
            console.error('Error linking class:', error);
        }
    };

    const handleUnlinkClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/linkClass/unlink/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                const updatedUser = await response.json();
                localStorage.setItem('user', JSON.stringify(updatedUser));
                alert('Class unlinked successfully!');
                window.location.reload(); // Reload to update the UI
            } else {
                alert('Failed to unlink class.');
            }
        } catch (error) {
            console.error('Error unlinking class:', error);
        }
    };

    const saveClass = async () => {
        const updatedClass = { ...selectedClass };
        delete updatedClass.isLinked; // Remove isLinked field before sending to backend

        const method = selectedClass._id ? 'PUT' : 'POST';
        const url = selectedClass._id
            ? `${process.env.REACT_APP_BASE_URL}/classes/${selectedClass._id}`
            : `${process.env.REACT_APP_BASE_URL}/classes`;

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(updatedClass),
            });

            if (response.ok) {
                const data = await response.json();
                alert(`Class ${method === 'POST' ? 'created' : 'updated'} successfully!`);
                setEditing(false);
                setClasses(classes.map(c => c._id === data._id ? data : c));
                if (method === 'POST') {
                    setClasses([...classes, data]); // Add new class to the list
                }
            } else {
                alert(`Failed to ${method === 'POST' ? 'create' : 'update'} class.`);
            }
        } catch (error) {
            console.error(`Error ${method === 'POST' ? 'creating' : 'updating'} class:`, error);
        }
    };

    const softDeleteClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/classes/delete/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                alert('Class moved to deleted list successfully!');
                setClasses(classes.filter(c => c._id !== classId));
                setSelectedClass(null);
                setShowDeleteWarning(false);
                const deletedClass = classes.find(c => c._id === classId);
                setDeletedClasses([...deletedClasses, { ...deletedClass, isDeleted: true }]);
            } else {
                alert('Failed to move class to deleted list.');
            }
        } catch (error) {
            console.error('Error moving class to deleted list:', error);
        }
    };

    const restoreClass = async (classId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/classes/restore/${classId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (response.ok) {
                alert('Class restored successfully!');
                const restoredClass = deletedClasses.find(c => c._id === classId);
                setDeletedClasses(deletedClasses.filter(c => c._id !== classId));
                setClasses([...classes, { ...restoredClass, isDeleted: false }]);
            } else {
                alert('Failed to restore class.');
            }
        } catch (error) {
            console.error('Error restoring class:', error);
        }
    };

    const handleNewClassNameChange = (e) => setNewClassName(e.target.value);
    const handleNewClassRollChange = (e) => setNewClassRoll(e.target.value);

    const handleNewClassFormSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submitted");
        console.log("New class name:", newClassName);
        console.log("New class roll:", newClassRoll);

        // Convert newClassRoll to an array of objects
        const role = newClassRoll.split('\n').map(line => {
            const names = line.split('\t').map(name => name.trim());
            return { firstName: names[0], lastName: names[1] || '' };
        }).filter(student => student.firstName);

        // Send class data to the backend
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/classes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    className: newClassName,
                    role,
                    teacher: user._id,
                }),
            });

            if (response.ok) {
                // Handle successful class creation
                const newClass = await response.json();
                setClasses([...classes, newClass]);
                alert('Class created successfully!');
                setCreatingNewClass(false);
                setNewClassName('');
                setNewClassRoll('');
            } else {
                // Handle error
                alert('Failed to create class.');
            }
        } catch (error) {
            console.error('Error creating class:', error);
        }
    };

    return (
        <div className="container fade-up">
            <div className="sidebar">
                <h1 className="header">Available Classes</h1>
                <ul className="class-list">
                    {classes.map((classItem) => (
                        <li
                            key={classItem._id}
                            className={`class-item ${selectedClass && selectedClass._id === classItem._id ? 'selected' : ''}`}
                            onClick={() => handleClassClick(classItem)}
                        >
                            <span>{classItem.className} {user.years.includes(classItem._id) ? '(Linked)' : ''}</span>
                            {user.years.includes(classItem._id) ? (
                                <button className="button" onClick={() => handleUnlinkClass(classItem._id)}>Unlink Class</button>
                            ) : (
                                <button className="button primary" onClick={() => handleLinkClass(classItem._id)}>Link Class</button>
                            )}
                        </li>
                    ))}
                </ul>
                <button className="button primary" onClick={() => setCreatingNewClass(true)}>Create New Class</button>
                <button className="button" onClick={() => setShowDeletedClasses(!showDeletedClasses)}>
                    {showDeletedClasses ? 'Hide Deleted Classes' : 'Show Deleted Classes'}
                </button>
            </div>
            <div className="main-content">
                {selectedClass && (
                    <div>
                        <div className="header">{selectedClass.className} - Student List</div>

                        {editing ? (
                            <>
                                <button className="button danger" onClick={() => setShowDeleteWarning(true)}>Delete Class</button>
                                <input
                                    className="input"
                                    type="text"
                                    value={selectedClass.className}
                                    onChange={handleClassNameChange}
                                />
                            </>
                        ) : (
                            <h3>{selectedClass.className}</h3>
                        )}
                        <ul>
                            {selectedClass.role.map((student, index) => (
                                <li key={index} className="class-item-edit">
                                    {editing ? (
                                        <div className="flex-container">
                                            <input
                                                className="input compact"
                                                type="text"
                                                value={student.firstName}
                                                onChange={(e) => handleStudentChange(e, index, 'firstName')}
                                            />
                                            <input
                                                className="input compact"
                                                type="text"
                                                value={student.lastName}
                                                onChange={(e) => handleStudentChange(e, index, 'lastName')}
                                            />
                                            <button className="button danger" onClick={() => removeStudent(index)}>Remove</button>
                                        </div>
                                    ) : (
                                        `${student.firstName} ${student.lastName}`
                                    )}
                                </li>
                            ))}
                        </ul>
                        {editing && (
                            <>
                                <div className="flex-container">
                                    <input
                                        className="input compact"
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name"
                                        value={newStudent.firstName}
                                        onChange={handleNewStudentChange}
                                    />
                                    <input
                                        className="input compact"
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={newStudent.lastName}
                                        onChange={handleNewStudentChange}
                                    />
                                    <button className="button primary" onClick={addNewStudent}>Add Student</button>
                                </div>
                                <button className="button primary" onClick={saveClass}>Save</button>
                            </>
                        )}
                        <button className="button" onClick={() => setEditing(!editing)}>
                            {editing ? 'Cancel' : 'Edit'}
                        </button>
                    </div>
                )}
            </div>

            {creatingNewClass && (
                <div className="classManager-modal">
                    <div className="classManager-modal-content">
                        <form className="classManager-form" onSubmit={handleNewClassFormSubmit}>
                            <h2>Create a New Class</h2>
                            <div>
                                <label>Class Name:</label>
                                <input className="classManager-input" type="text" value={newClassName} onChange={handleNewClassNameChange} required />
                            </div>
                            <div>
                                <label>Class Roll (First Name\Last Name per line):</label>
                                <textarea className="classManager-textarea" value={newClassRoll} onChange={handleNewClassRollChange} rows="10" required />
                            </div>
                            <button className="classManager-button classManager-button-primary" type="submit">Create Class</button>
                            <button className="classManager-button" type="button" onClick={() => setCreatingNewClass(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}

            {showDeleteWarning && (
                <div className="classManager-modal">
                    <div className="classManager-modal-content">
                        <h2>Warning!</h2>
                        <p>When we delete a Class, we keep an archived copy of it available to you in case you wish to restore it.</p>
                        <button className="classManager-button classManager-button-danger" onClick={() => softDeleteClass(selectedClass._id)}>Delete</button>
                        <button className="classManager-button" onClick={() => setShowDeleteWarning(false)}>Cancel</button>
                    </div>
                </div>
            )}

            {showDeletedClasses && (
                <div className="classManager-modal">
                    <div className="classManager-modal-content">
                        <h2>Deleted Classes</h2>
                        <ul className="classManager-class-list">
                            {deletedClasses.map((classItem) => (
                                <li key={classItem._id} className="classManager-class-item" onClick={() => handleClassClick(classItem)}>
                                    <span>{classItem.className}</span>
                                    <button className="classManager-button classManager-button-primary" onClick={() => restoreClass(classItem._id)}>Restore</button>
                                </li>
                            ))}
                        </ul>
                        <button className="classManager-button" onClick={() => setShowDeletedClasses(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClassManager;
