import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, Sector } from 'recharts';
import '../../css/BehaviorGraph.css';

const COLORS = [
    '#0088FE', // Bright Blue
    '#00C49F', // Light Green
    '#FFBB28', // Yellow
    '#FF8042', // Orange
    '#FF6384', // Pink
    '#2e73a4', // Light Blue
    '#FFCE56', // Pale Yellow
    '#4BC0C0', // Aqua
    '#9966FF', // Purple
    '#FF9F40', // Coral
];

const renderCustomLabel = ({ name, value, percent }) => {
    return `${(percent * 100).toFixed(0)}%`;
};

const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const BehaviorGraph = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    if (!Array.isArray(data)) {
        return <p>No data available</p>;
    }

    return (
        <div className="behavior-graph-container">
            <h2>Behavior Graph</h2>
            <h4>Analysis of Student Behavior</h4>
            <PieChart width={400} height={450} className="pie-chart">
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx={200}
                    cy={200}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                    label={renderCustomLabel}
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
                <div style={{ height: '50px' }}></div>
                <Legend />
            </PieChart>
        </div>
    );
};

export default BehaviorGraph;
