import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import '../../css/Reports.css'

const SummaryReport = ({ data, generateReportWithType }) => {
    const [selectedOption, setSelectedOption] = useState(() => {
        return localStorage.getItem('selectedReportOption') || 'general';
    });

    const options = [
        { label: 'General', value: 'general' },
        { label: 'Behavior', value: 'behavior' },
        { label: 'Progress', value: 'progress' },
        { label: 'Maths Paragraph', value: 'shortMaths' },
    ];

    // useEffect(() => {
    //     generateReportWithType(selectedOption); // Notify parent component of the initial option
    // }, [selectedOption, generateReportWithType]);

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        localStorage.setItem('selectedReportOption', value); // Save the selected option
        generateReportWithType(value); // Notify parent component of the option change
    };

    const copyToClipboard = () => {
        const textToCopy = data.summary;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Summary copied to clipboard!');
            }).catch((err) => {
                console.error('Failed to copy: ', err);
            });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                alert('Summary copied to clipboard!');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <div>
            <div className="options-container">
                {options.map(option => (
                    <button
                        key={option.value}
                        onClick={() => handleOptionChange(option.value)}
                        className={selectedOption === option.value ? 'selected' : ''}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
            <ReactMarkdown>{data.summary}</ReactMarkdown>
            <button className="copy-button" onClick={copyToClipboard}>Copy Summary to Clipboard</button>
        </div>
    );
};

export default SummaryReport;
