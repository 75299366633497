import React, { useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import '../../css/NewsletterEditor.css';
import { debounce } from "chart.js/helpers";
import newsletterTemplate from '../../templates/newsletterTemplate';
import announcementTemplate from '../../templates/announcementTemplate';
import newsletterTwoTemplate from '../../templates/tammooNewsletterTemplate';
import invitationTemplate from '../../templates/invitationTemplate';

const templateOptions = {
    'Newsletter': newsletterTemplate,
    'Newsletter2': newsletterTwoTemplate,
    'Announcement': announcementTemplate,
    'Invitation': invitationTemplate,
};

const NewsletterEditor = () => {
    const editorRef = useRef(null);
    const [content, setContent] = useState(newsletterTemplate);
    const [version, setVersion] = useState('');
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [customTag, setCustomTag] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('Newsletter');
    const [testMode, setTestMode] = useState(true); // Add state for test/production mode

    const predefinedTags = ['Newsletter', 'Update', 'General', 'Voting'];

    useEffect(() => {
        fetchVersions();
    }, []);

    // Update content when template changes
    useEffect(() => {
        if (window.confirm('Switching templates will overwrite your current changes. Do you want to proceed?')) {
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setContent(templateOptions[selectedTemplate]);
        }
    }, [selectedTemplate]);

    // Fetch newsletter versions
    const fetchVersions = async () => {
        try {
            const response = await axios.get('https://www.tammoo.com/tammoomanage/newsletters/versions');
            if (response.data) {
                setVersions(response.data);
            }
        } catch (error) {
            console.error('Error fetching newsletter versions:', error);
        }
    };

    const saveAndSendNewsletter = async () => {
        const savedNewsletter = await saveNewsletter();
        if (savedNewsletter) {
            try {
                const editedContent = editorRef.current.getContent();
                const fullContent = templateOptions[selectedTemplate].replace(
                    /<body>[\s\S]*<\/body>/,
                    `<body>${editedContent}</body>`
                );
                await axios.post('https://www.tammoo.com/tammoomanage/newsletters/send-newsletter', {
                    content: fullContent,
                    tags: selectedTags,
                    testMode, // Pass the testMode flag
                });
                alert('Newsletter saved and sent successfully!');
            } catch (error) {
                console.error('Error sending newsletter:', error);
                alert('Failed to send newsletter.');
            }
        }
    };

    const debouncedSetContent = useRef(debounce((newContent) => {
        setContent(newContent);
    }, 300)).current;

    const saveNewsletter = async () => {
        if (!version) {
            alert('Please enter a version name.');
            return null;
        }

        const editedContent = editorRef.current.getContent();
        const fullContent = templateOptions[selectedTemplate].replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );

        try {
            const response = await axios.post('https://www.tammoo.com/tammoomanage/newsletters/save', {
                content: fullContent,
                version,
                tags: selectedTags
            });
            alert('Newsletter saved successfully!');
            await fetchVersions();
            return response.data;
        } catch (error) {
            console.error('Error saving newsletter:', error);
            alert('Failed to save newsletter.');
            return null;
        }
    };

    const loadVersion = async (version) => {
        try {
            const response = await axios.get(`https://www.tammoo.com/tammoomanage/newsletters/${version}`);
            if (response.data && response.data.content) {
                setContent(response.data.content);
                setSelectedVersion(version);
                setSelectedTags(response.data.tags || []);
                if (editorRef.current) {
                    editorRef.current.setContent(response.data.content);
                }
            }
        } catch (error) {
            console.error('Error loading newsletter version:', error);
        }
    };

    const resetToDefaultTemplate = () => {
        if (window.confirm('This will reset the content to the default template. Do you want to proceed?')) {
            setContent(templateOptions[selectedTemplate]);
            if (editorRef.current) {
                editorRef.current.setContent(templateOptions[selectedTemplate]);
            }
            setSelectedTags([]);
        }
    };

    const sendNewsletter = async () => {
        try {
            const editedContent = editorRef.current.getContent();
            const fullContent = templateOptions[selectedTemplate].replace(
                /<body>[\s\S]*<\/body>/,
                `<body>${editedContent}</body>`
            );
            await axios.post('https://www.tammoo.com/tammoomanage/newsletters/send-newsletter', {
                content: fullContent,
                tags: selectedTags,
                testMode, // Pass the testMode flag
            });
            alert('Newsletter sent successfully!');
        } catch (error) {
            console.error('Error sending newsletter:', error);
            alert('Failed to send newsletter.');
        }
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const getFullContent = () => {
        const editedContent = editorRef.current.getContent();
        return templateOptions[selectedTemplate].replace(
            /<body>[\s\S]*<\/body>/,
            `<body>${editedContent}</body>`
        );
    };

    const handleTagSelection = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    const handleCustomTagInput = (e) => {
        setCustomTag(e.target.value);
    };

    const addCustomTag = () => {
        if (customTag && !selectedTags.includes(customTag)) {
            setSelectedTags([...selectedTags, customTag]);
            setCustomTag('');
        }
    };

    return (
        <div className="newsletter-editor">
            <div className="editor-header">
                <div className="version-control">
                    <input
                        type="text"
                        placeholder="Version"
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                    />
                    <select
                        value={selectedVersion}
                        onChange={(e) => loadVersion(e.target.value)}
                    >
                        <option value="">Select a version</option>
                        {versions.map((v) => (
                            <option key={v.version} value={v.version}>
                                {v.version} - {new Date(v.date).toLocaleDateString()}
                            </option>
                        ))}
                    </select>
                    <button onClick={resetToDefaultTemplate}>New</button>
                </div>
                <div className="template-selection">
                    <label>Select Template: </label>
                    <select
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                    >
                        {Object.keys(templateOptions).map(template => (
                            <option key={template} value={template}>
                                {template}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="test-mode-toggle">
                    <label>
                        <input
                            type="checkbox"
                            checked={testMode}
                            onChange={(e) => setTestMode(e.target.checked)}
                        /> Test Mode
                    </label>
                </div>
                <div className="tag-selection">
                    {predefinedTags.map(tag => (
                        <button
                            key={tag}
                            onClick={() => handleTagSelection(tag)}
                            className={selectedTags.includes(tag) ? 'selected' : ''}
                        >
                            {tag}
                        </button>
                    ))}
                    <input
                        type="text"
                        value={customTag}
                        onChange={handleCustomTagInput}
                        placeholder="Custom tag"
                        onKeyPress={(e) => e.key === 'Enter' && addCustomTag()}
                    />
                    <button onClick={addCustomTag}>+</button>
                </div>
            </div>
            <div className="selected-tags">
                Tags: {selectedTags.join(', ') || 'None'}
            </div>
            <Editor
                apiKey="2a21s1kjn13r7oo10wd0s74qjc98em3ec9ioth1ymk5bpkov"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists',
                        'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={(newContent) => debouncedSetContent(newContent)}
            />
            <div className="editor-footer">
                <button onClick={saveNewsletter}>Save</button>
                <button onClick={sendNewsletter}>Send</button>
                <button onClick={saveAndSendNewsletter}>Save & Send</button>
                <button onClick={togglePreview}>Preview</button>
            </div>
            {showPreview && (
                <div className="preview-modal">
                    <div className="preview-content" dangerouslySetInnerHTML={{ __html: getFullContent() }} />
                    <button className="close-button" onClick={togglePreview}>Close Preview</button>
                </div>
            )}
        </div>
    );
};

export default NewsletterEditor;
