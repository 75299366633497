const tammooNewsletterTemplate = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tammoo App Update</title>
    <style>
        /* General body styling */
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            box-sizing: border-box; /* Ensures consistent box-sizing */
        }
        
        /* Container for the newsletter */
        .container {
            width: 100%;
            max-width: 600px;
            margin: 20px auto; /* Centered with top margin */
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            overflow: hidden;
            display: flex;
            flex-direction: column; /* Vertical stacking */
            box-sizing: border-box; /* Include padding in width */
        }

        /* Header section styling */
        .header {
            background-color: #4CAF50;
            color: #ffffff;
            padding: 20px;
            text-align: center;
            border-radius: 8px 8px 0 0;
            box-sizing: border-box;
        }
        
        /* Content section styling */
        .content {
            padding: 20px;
            box-sizing: border-box;
        }
        
        /* Headings inside content */
        .content h2 {
            color: #333333;
            margin-top: 0; /* Aligns with content section */
        }

        /* Paragraphs inside content */
        .content p {
            color: #666666;
            line-height: 1.6;
            margin: 10px 0; /* Space between paragraphs */
        }
        
        /* Roadmap section styling */
        .content .roadmap {
            margin-top: 20px;
            padding: 20px;
            background-color: #e7f7e7;
            border-radius: 5px;
            box-sizing: border-box; /* Include padding */
        }
        
        /* Roadmap heading styling */
        .content .roadmap h3 {
            margin-top: 0; /* Aligns with roadmap section */
        }

        /* Footer section styling */
        .footer {
            background-color: #f1f1f1;
            color: #777777;
            text-align: center;
            padding: 10px;
            border-radius: 0 0 8px 8px;
            box-sizing: border-box; /* Include padding */
        }
        
        /* Subtext styling */
        .subtext {
            font-size: 14px;
            color: #888888;
        }
        
        /* Button styling */
        .button {
            width: 30%;
            display: inline-block;
            padding: 10px 20px;
            margin: 10px 5px;
            font-size: 16px;
            color: #ffffff;
            background-color: #4CAF50;
            border: none;
            border-radius: 5px;
            text-decoration: none;
            text-align: center;
            box-sizing: border-box;
        }

        /* Optional: Anonymity text styling */
        .anon {
            font-size: smaller;
            color: #aaa;
        }
    </style>
</head>
<body>
<div class="container">
    <div class="header">
        <h1>Tammoo App Update</h1>
    </div>
    <div class="content">
        <h2>Hello Tammoo Beta Testers,</h2>
        <p>We are excited to bring you the latest updates to the Tammoo app. Your feedback has been invaluable in helping us improve the app. Here are some of the new features and improvements in this update:</p>
        <ul>
            <li>Update 1</li>
            <p class="subtext">Description</p>
            <li>Update 2</li>
            <p class="subtext">Description</p>
        </ul>
        <div class="roadmap">
            <h3>What's Coming Up</h3>
            <p>We are continuously working on new features and improvements. Here's a sneak peek at what's on our roadmap:</p>
            <ul>
                <li>Feature 1</li>
                <p class="subtext">Description.</p>
                <li>Feature 2</li>
                <p class="subtext">Description.</p>
                <li>Feature 3</li>
                <p class="subtext">Description.</p>
            </ul>
            <div>
                <p>What do you think of this idea? <span class="anon">All Voting is Anonymous</span></p>
                <a href="https://www.tammoo.com/webform/vote?reason=NewFeature&vote=like" class="button">Like</a>
                <a href="https://www.tammoo.com/webform/vote?reason=NewFeature&vote=neutral" class="button">Neutral</a>
                <a href="https://www.tammoo.com/webform/vote?reason=NewFeature&vote=dislike" class="button">Not Needed</a>
            </div>
        </div>
        <p>Thank you for your continued support and feedback. Together, we are making Tammoo better every day.</p>
        <p>Best regards,<br>The Tammoo Team</p>
    </div>
    <div class="footer">
        <p>&copy; 2024 Tammoo. All rights reserved.</p>
        <p>Questions? Visit our <a href="https://www.tammoo.com">site</a>.</p>
    </div>
</div>
</body>
</html>
`;

export default tammooNewsletterTemplate;
