const newsletterTemplate = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tammoo Newsletter</title>
    <style>
        /* General reset and body styling */
        body {
            font-family: Arial, sans-serif;
            background-color: #f0f0f0;
            margin: 0;
            padding: 0;
            box-sizing: border-box; /* Ensure consistent box sizing */
        }
        
        /* Container for the newsletter */
        .container {
            max-width: 600px;
            margin: 20px auto;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            display: flex;
            flex-direction: column; /* Stack children vertically */
        }
        
        /* Header styling */
        .header {
            background: #4CAF50;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-radius: 10px 10px 0 0;
            box-sizing: border-box; /* Include padding in height */
        }

        /* Main content area styling */
        .content {
            padding: 20px;
            line-height: 1.6;
        }
        
        /* Footer styling */
        .footer {
            background: #f1f1f1;
            color: #555;
            text-align: center;
            padding: 10px;
            border-radius: 0 0 10px 10px;
            box-sizing: border-box; /* Include padding in height */
        }
    </style>
</head>
<body>
<div class="container">
    <div class="header">
        <h1>Tammoo Newsletter</h1>
    </div>
    <div class="content">
        <h2>Welcome to the Tammoo Newsletter</h2>
        <p>This is your source for the latest updates and insights about the Tammoo app.</p>
    </div>
    <div class="footer">
        <p>&copy; 2024 Tammoo. All rights reserved.</p>
    </div>
</div>
</body>
</html>
`;

export default newsletterTemplate;
