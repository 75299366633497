import React from 'react';

const ClassSelector = ({ classes, onSelect, selectedClass }) => {
    return (
        <select
            value={selectedClass ? selectedClass._id : ''}
            onChange={(e) => {
                const selected = classes.find(c => c._id === e.target.value);
                console.log("Selected class in ClassSelector:", selected);
                onSelect(selected);
            }}
        >
            <option value="">Select a Class</option>
            {classes.map((c) => (
                <option key={c._id} value={c._id}>
                    {c.className} - {c.teacher.firstName} {c.teacher.lastName}
                </option>
            ))}
        </select>
    );
};

export default ClassSelector;
