import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/TermManager.css';
import TimetableManager from './TimetableManager';

const TermManager = () => {
    const [terms, setTerms] = useState([]);
    const [newTerm, setNewTerm] = useState({ termNumber: '', startDate: '', endDate: '' });
    const [editingTerm, setEditingTerm] = useState(null);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/terms'
            });
        }
    }, []);

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/terms`);
                setTerms(response.data);
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        fetchTerms();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTerm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editingTerm
                ? await axios.put(`${process.env.REACT_APP_BASE_URL}/terms/${editingTerm.termNumber}`, newTerm)
                : await axios.post(`${process.env.REACT_APP_BASE_URL}/terms`, newTerm);

            setTerms(prevState => editingTerm
                ? prevState.map(term => term.termNumber === editingTerm.termNumber ? response.data : term)
                : [...prevState, response.data]
            );
            setNewTerm({ termNumber: '', startDate: '', endDate: '' });
            setEditingTerm(null);
            setShowForm(false);
        } catch (error) {
            console.error('Error saving term:', error);
        }
    };

    const handleEdit = (term) => {
        setNewTerm({
            termNumber: term.termNumber,
            startDate: new Date(term.startDate).toISOString().split('T')[0],
            endDate: new Date(term.endDate).toISOString().split('T')[0]
        });
        setEditingTerm(term);
        setShowForm(true);
    };

    const handleDelete = async (termNumber) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this term?');
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/terms/${termNumber}`);
                setTerms(prevState => prevState.filter(term => term.termNumber !== termNumber));
            } catch (error) {
                console.error('Error deleting term:', error);
            }
        }
    };



    return (
        <div className="term-manager">
            <h1>Manage School Terms</h1>
            <button onClick={() => setShowForm(!showForm)} className="term-button">
                {showForm ? 'Cancel' : 'Add New Term'}
            </button>
            {showForm && (
                <form onSubmit={handleSubmit} className="form">
                    <div>
                        <label>Term Number</label>
                        <input
                            type="number"
                            name="termNumber"
                            value={newTerm.termNumber}
                            onChange={handleInputChange}
                            required
                            disabled={!!editingTerm}
                        />
                    </div>
                    <div>
                        <label>Start Date</label>
                        <input
                            type="date"
                            name="startDate"
                            value={newTerm.startDate}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label>End Date</label>
                        <input
                            type="date"
                            name="endDate"
                            value={newTerm.endDate}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit" className="button primary">
                        {editingTerm ? 'Update Term' : 'Add Term'}
                    </button>
                </form>
            )}
            <ul className="term-list">
                {terms.map(term => (
                    <li key={term.termNumber} className="term-item">
                        <span>Term {term.termNumber}: {new Date(term.startDate).toLocaleDateString()} - {new Date(term.endDate).toLocaleDateString()}</span>
                        <button onClick={() => handleEdit(term)} className="term-button">Edit</button>
                        <button onClick={() => handleDelete(term.termNumber)} className="term-button danger">Delete</button>
                    </li>
                ))}
            </ul>
            <TimetableManager /> {/* Add TimetableManager here */}
        </div>
    );
};

export default TermManager;
