import React from 'react';
import {
    ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const BehaviorTimeGraph = ({ data }) => {
    if (!Array.isArray(data)) {
        return <p>Invalid data format. Please provide an array for processing.</p>;
    }

    const getColorPalette = () => {
        return ["#8884d8", "#82ca9d", "#ffc658", "#ff4d4d", "#8dd1e1", "#d0ed57"];
    };

    const processData = (data) => {
        const uniqueStates = [...new Set(data.map(d => d.state))];
        const stateToIndex = Object.fromEntries(uniqueStates.map((state, index) => [state, index]));

        return data.map(record => {
            const date = new Date(record.date);
            return {
                time: date.getHours() + date.getMinutes() / 60,
                state: record.state,
                stateIndex: stateToIndex[record.state]
            };
        });
    };

    const processedData = processData(data);
    const uniqueStates = [...new Set(processedData.map(d => d.state))];
    const colors = getColorPalette();

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
                margin={{
                    top: 20, right: 20, bottom: 20, left: 20,
                }}
            >
                <CartesianGrid />
                <XAxis
                    type="number"
                    dataKey="time"
                    name="Time of Day"
                    domain={[9, 15]}
                    tickFormatter={(tick) => `${Math.floor(tick)}:${String(Math.round((tick % 1) * 60)).padStart(2, '0')}`}
                />
                <YAxis
                    type="number"
                    dataKey="stateIndex"
                    name="State"
                    domain={[0, uniqueStates.length - 1]}
                    tickFormatter={(tick) => uniqueStates[tick]}
                    interval={0}
                />
                <Tooltip
                    formatter={(value, name, props) => {
                        if (name === 'time') {
                            const hours = Math.floor(value);
                            const minutes = Math.round((value % 1) * 60);
                            return [`${hours}:${String(minutes).padStart(2, '0')}`, 'Time'];
                        }
                        return [props.payload.state, 'State'];
                    }}
                />
                <Legend />
                {uniqueStates.map((state, index) => (
                    <Scatter
                        key={state}
                        name={state}
                        data={processedData.filter(d => d.state === state)}
                        fill={colors[index % colors.length]}
                    />
                ))}
            </ScatterChart>
        </ResponsiveContainer>
    );
};

export default BehaviorTimeGraph;
