import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import '../../css/LandingPage.css';
import { isAuthenticated } from "../../auth";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import mascotImage3 from '../../assets/bg-guy3.png';
import mascotImage2 from '../../assets/bg-guy2.png';
import mascotImage from '../../assets/bg-guy.png';

const LandingPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true,
        });
    }, []);

    // if (isAuthenticated()) {
    //     return <Navigate to="/dash" />;
    // }

    return (
        <div className="landing-page">
            <section className="hero">
                <img src={mascotImage3} alt="Mascot3" className="mascot top-left" />
                <h1 data-aos="fade-up">Transform Your Voice into Actionable Classroom Data</h1>
                <p data-aos="fade-up" data-aos-delay="200">Speak, Analyze, and Gain Insights with Tammoo</p>
                <div data-aos="fade-up" data-aos-delay="400">
                    <Link to="/contact" className="cta-button">Get Started</Link>
                </div>
            </section>

            <section className="key-features">
                <h2 data-aos="fade-up">Key Features</h2>
                <div className="features-container">
                    <Feature
                        icon="fas fa-microphone-alt"
                        title="Speech-to-Text"
                        description="Instantly convert your spoken notes into text"
                    />
                    <Feature
                        icon="fas fa-robot"
                        title="AI Analysis"
                        description="Automatically analyze and categorize your data"
                    />
                    <Feature
                        icon="fas fa-folder-open"
                        title="Data Management"
                        description="Organize and format your insights effortlessly"
                    />
                    <Feature
                        icon="fas fa-chart-line"
                        title="Flexible Reporting"
                        description="Generate reports for any timeframe - Term, Month, Week, or Day"
                    />
                    <Feature
                        icon="fas fa-file-export"
                        title="Export Options"
                        description="Export your data as CSV for further analysis"
                    />
                    <Feature
                        icon="fas fa-users"
                        title="Real-Time Collaboration"
                        description="Work together with colleagues seamlessly"
                    />
                </div>
            </section>

            <section className="how-it-works">
                <h2 data-aos="fade-up">How It Works</h2>
                <div className="steps-container">
                    <Step
                        number="1"
                        title="Speak"
                        description="Record your observations using the mobile app"
                    />
                    <Step
                        number="2"
                        title="Analyze"
                        description="Let AI process and categorize your data"
                    />
                    <Step
                        number="3"
                        title="Gain Insights"
                        description="Generate reports and extract valuable information"
                    />
                </div>
            </section>

            <section className="testimonials">
                <img src={mascotImage2} alt="Mascot" className="mascot bottom-right" />
                <h2 data-aos="fade-up">What Teachers Are Saying</h2>
                <blockquote data-aos="fade-up" data-aos-delay="200">
                    <p>"Tammoo has completely transformed the way I manage classroom data. It's easy to use and saves me so much time!"</p>
                    <cite>- John Doe, 5th Grade Teacher, Sunshine Elementary</cite>
                </blockquote>
            </section>

            <section className="cta">
                <img src={mascotImage} alt="Mascot" className="mascot bottom-left" />
                <h2 data-aos="fade-up">Ready to Revolutionize Your Classroom Data?</h2>
                <Link to="/contact" className="cta-button" data-aos="fade-up" data-aos-delay="200">Get Started</Link>
            </section>

            <footer>
                <p>Contact Us: info@tammoo.com</p>
                <p><Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms of Service</Link></p>
                <div className="social-media">
                    <a href="#"><img src="icons/facebook.png" alt="Facebook" /></a>
                    <a href="#"><img src="icons/twitter.png" alt="Twitter" /></a>
                    <a href="#"><img src="icons/linkedin.png" alt="LinkedIn" /></a>
                </div>
            </footer>
        </div>
    );
};

const Feature = ({ icon, title, description }) => (
    <div className="feature" data-aos="fade-up">
        <i className={icon}></i>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

const Step = ({ number, title, description }) => (
    <div className="step" data-aos="fade-up">
        <div className="step-number">{number}</div>
        <h3>{title}</h3>
        <p>{description}</p>
    </div>
);

export default LandingPage;
