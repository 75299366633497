import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ClassSelector from '../Classes/ClassSelector';
import DataTable from './DataTable';
import ThreeMonthCalendar from './ThreeMonthCalendar';
import '../../css/Home.css';
import Papa from 'papaparse';
import { DNA } from 'react-loader-spinner';

const Home = () => {
    const [terms, setTerms] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(() => {
        const savedClass = localStorage.getItem('selectedClass');
        try {
            return savedClass ? JSON.parse(savedClass) : null;
        } catch (e) {
            console.error('Error parsing saved class from localStorage', e);
            return null;
        }
    });
    const [data, setData] = useState([]);
    const [role, setRole] = useState([]);
    const [currentDate, setCurrentDate] = useState(() => {
        const savedDate = localStorage.getItem('currentDate');
        const parsedDate = savedDate ? new Date(savedDate) : new Date();
        return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    });
    const [dateRange, setDateRange] = useState(() => {
        const savedRange = localStorage.getItem('dateRange');
        if (savedRange) {
            try {
                return JSON.parse(savedRange).map((date) => new Date(date));
            } catch (e) {
                console.error('Error parsing saved date range from localStorage', e);
                // Optionally remove the invalid item from localStorage
                localStorage.removeItem('dateRange');
                return [new Date(), new Date()];
            }
        } else {
            return [new Date(), new Date()];
        }
    });
    const [tableState, setTableState] = useState({ columns: [], rows: [] });
    const [selectedName, setSelectedName] = useState(() => localStorage.getItem('selectedName') || null);
    const [showCalendar, setShowCalendar] = useState(false);
    const [recordDates, setRecordDates] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState(() => {
        const savedTerms = localStorage.getItem('selectedTerms');
        return savedTerms ? JSON.parse(savedTerms) : [];
    });
    const [dateRangeKey, setDateRangeKey] = useState(0);
    const navigate = useNavigate();
    const displayName = useMemo(() => selectedName || 'class', [selectedName]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [showEditModal, setShowEditModal] = useState();
    const [selectedClassId, setSelectedClassId] = useState(() => {
        const savedClassId = localStorage.getItem('selectedClassId');
        return savedClassId ? JSON.parse(savedClassId) : null;
    });
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [renderKey, setRenderKey] = useState(0);


    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/dash'
            });
        }
    }, []);

    useEffect(() => {
        const savedClass = localStorage.getItem('selectedClass');

        if (savedClass) {
            console.log('GOT', savedClass);
            handleClassSelect(savedClass._id)
            try {
                const parsedClass = JSON.parse(savedClass);
                setSelectedClass(parsedClass);
                setRole(parsedClass.role || []); // Set the role if it exists
            } catch (e) {
                console.error('Error parsing saved class from localStorage', e);
            }
        }
    }, []);

    const handleEdit = (classId, record) => {
        setSelectedRecord(record);  // Store the selected record
        setShowEditModal(true);  // Open the modal
    };

    const handleSaveRecord = async (updatedRecord) => {
        const date = new Date(updatedRecord.date);
        const year = date.getFullYear().toString();
        const day = date.toISOString().split('T')[0];
        console.log('IN SAVE RECORD', updatedRecord);
        try {
            const response = await fetch(`${BASE_URL}/records/${selectedClass._id}/${year}/${day}/${updatedRecord._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(updatedRecord),
            });

            if (response.ok) {
                const updatedData = await response.json();

                setData((prevData) => {
                    const newData = prevData.map((r) =>
                        r._id === updatedData._id ? updatedData : r
                    );
                    return [...newData];  // Spread to ensure a new array reference
                });
                setRenderKey((prevKey) => prevKey + 1);  // Trigger re-render
                await fetchData();
            } else {
                console.error('Failed to update record');
            }
        } catch (error) {
            console.error('Error updating record:', error);
        }
    };

    const handleDelete = async (classId, record) => {
        // Extract the date portion (YYYY-MM-DD) and the year from the ISO string
        const date = new Date(record.date);
        const year = date.getFullYear().toString(); // Extract the year (e.g., 2024)
        const day = date.toISOString().split('T')[0]; // Extract the date part (e.g., "2024-08-09")

        // Log what you're about to delete
        console.log('GONNA DELETE', selectedClass._id, year, day, record._id);

        if (window.confirm("Are you sure you want to delete this item?")) {
            try {
                const response = await fetch(`${BASE_URL}/records/${selectedClass._id}/${year}/${day}/${record._id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.ok) {
                    // Update state to remove the deleted record
                    setData((prevData) => prevData.filter(r => r._id !== record._id));
                } else {
                    console.error('Failed to delete record');
                }
            } catch (error) {
                console.error('Error deleting record:', error);
            }
        }
    };



    useEffect(() => {
        const initializeData = async () => {
            const savedClass = localStorage.getItem('selectedClass');
            const savedDate = localStorage.getItem('currentDate');
            const savedRange = localStorage.getItem('dateRange');
            const savedName = localStorage.getItem('selectedName');
            const savedTerm = localStorage.getItem('selected-term');

            if (savedClass) {
                try {
                    const parsedClass = JSON.parse(savedClass);
                    setSelectedClass(parsedClass);
                    setRole(parsedClass.role || []);
                    if (savedRange) {
                        const parsedRange = JSON.parse(savedRange).map(date => new Date(date));
                        setDateRange(parsedRange);
                        setSelectedClassId(parsedClass._id);
                        setCurrentDate(parsedRange[0]);
                    } else {
                        const today = new Date();
                        setDateRange([today, today]);
                        setCurrentDate(today);
                    }

                    if (savedTerm) {
                        const parsedTerm = JSON.parse(savedTerm);
                        setDateRange([new Date(parsedTerm.startDate), new Date(parsedTerm.endDate)]);
                        setCurrentDate(new Date(parsedTerm.startDate));
                    }
                } catch (e) {
                    console.error('Error parsing saved data from localStorage', e);
                }
            }

            setSelectedName('class');
        };

        const fetchClasses = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/classes`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setClasses(response.data);
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        const fetchTerms = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/terms`);
                setTerms(response.data);

                const storedTerms = localStorage.getItem('selectedTerms');
                if (storedTerms) {
                    setSelectedTerms(JSON.parse(storedTerms));
                } else if (response.data.length > 0) {
                    setSelectedTerms([response.data[0].termNumber]);
                    localStorage.setItem('selectedTerms', JSON.stringify([response.data[0].termNumber]));
                }
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        const checkTokenValidity = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/validate-token`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            } catch (error) {
                console.error('Invalid or expired token:', error);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                navigate('/login');
            }
        };

        initializeData();
        fetchClasses();
        fetchTerms();
        checkTokenValidity();
    }, [navigate]);

    const fetchData = useCallback(async () => {
        if (selectedClass) {
            setIsLoading(true);

            const [startDate, endDate] = dateRange;
            const start = startDate.toISOString().split('T')[0];
            const end = endDate.toISOString().split('T')[0];
            const endpoint = `${process.env.REACT_APP_BASE_URL}/classes/${selectedClass._id}/range/${start}/${end}?page=${currentPage}&limit=100${selectedName ? `&studentName=${selectedName}` : ''}`;

            try {
                const response = await axios.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const { totalRecords, currentPage, totalPages, records } = response.data;
                setData(records);
                setTotalRecords(totalRecords);
                setCurrentPage(currentPage);
                setTotalPages(totalPages);
                setRole(selectedClass.role || []);
                fetchRecordDates(selectedClass._id, selectedName); // Ensure record dates are fetched
            } catch (error) {
                console.error(`Error fetching data for the selected range:`, error);
                setData([]);
            } finally {
                setIsLoading(false);
            }
        } else {
            setData([]);
            setIsLoading(false);
        }
    }, [selectedClass, currentPage, dateRange, selectedName]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchRecordDates = useCallback(async (classId, studentName = null) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/classes/${classId}/record-dates`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: { studentName: studentName === 'class' ? null : studentName } // Ensure correct param handling
            });
            setRecordDates(response.data.map(date => date.split('T')[0]));
        } catch (error) {
            console.error('Error fetching record dates:', error);
            setRecordDates([]);
        }
    }, []);

    useEffect(() => {
        console.log('SETUP!!!!')
        setRecordCount(data.length);
    }, [data]);

    useEffect(() => {
        localStorage.setItem('selectedClass', JSON.stringify(selectedClass));
    }, [selectedClass]);

    useEffect(() => {
        localStorage.setItem('currentDate', JSON.stringify(currentDate));
    }, [currentDate]);

    useEffect(() => {
        localStorage.setItem('dateRange', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        localStorage.setItem('selectedName', selectedName);
    }, [selectedName]);

    const navigatePrevious = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const navigateNext = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handleDateRangeChange = (range) => {
        if (range.length === 2) {
            setDateRange(range);
            setCurrentDate(range[0]);
            setCurrentPage(1);  // Reset to page 1
            setDateRangeKey(prevKey => prevKey + 1);  // Increment the key
        }
    };

    const handleTermChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value, 10));
        setSelectedTerms(selectedOptions);

        const selectedTermObjects = selectedOptions.map(termNumber => terms.find(term => term.termNumber === termNumber));
        const startDates = selectedTermObjects.map(term => new Date(term.startDate));
        const endDates = selectedTermObjects.map(term => new Date(term.endDate));

        const overallStartDate = new Date(Math.min(...startDates));
        const overallEndDate = new Date(Math.max(...endDates));

        setDateRange([overallStartDate, overallEndDate]);
        setCurrentDate(overallStartDate);
        setCurrentPage(1);  // Reset to page 1
        setDateRangeKey(prevKey => prevKey + 1);  // Increment the key

        localStorage.setItem('startDate', overallStartDate.toISOString().split('T')[0]);
        localStorage.setItem('endDate', overallEndDate.toISOString().split('T')[0]);
        localStorage.setItem('selectedTerms', JSON.stringify(selectedOptions));
    };

    const handleClassSelect = (selectedClass) => {
        setSelectedClass(selectedClass);
        localStorage.setItem('selectedClass', JSON.stringify(selectedClass));
        // If you still need selectedClassId for other purposes:
        setSelectedClassId(selectedClass ? selectedClass._id : null);
    };

    const handleStateChange = useCallback((newState) => {
        setTableState(newState);
    }, []);

    const handleExportCSV = async () => {
        if (!selectedClass || dateRange.length !== 2) {
            return;
        }

        const [startDate, endDate] = dateRange;
        const start = startDate.toISOString().split('T')[0];
        const end = endDate.toISOString().split('T')[0];
        const exportEndpoint = `${process.env.REACT_APP_BASE_URL}/classes/${selectedClass._id}/range/export/${start}/${end}?studentName=${selectedName !== 'class' ? selectedName : ''}`;

        try {
            const response = await axios.get(exportEndpoint, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            const { records } = response.data;

            const csvData = records.map(record => {
                const rowData = {};
                tableState.columns.forEach(col => {
                    if (record[col.key] !== undefined) {
                        rowData[col.label] = col.key === 'date' ? formatDate(record[col.key]) : record[col.key];
                    } else {
                        rowData[col.label] = '';
                    }
                });
                return rowData;
            });

            const csv = Papa.unparse(csvData);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', 'data.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting CSV:', error);
        }
    };

    useEffect(() => {
        const savedName = localStorage.getItem('selectedName');
        setSelectedName(prevName => {
            if (savedName && prevName !== savedName) {
                return savedName;
            }
            return prevName;
        });
    }, []);

    const handleNameClick = useCallback((name) => {
        setSelectedName(prevName => prevName === name ? 'class' : name); // Ensure 'class' is used to denote no student selected
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };

    useEffect(() => {
        if (data.length > 0) {
            setFilteredData(data);
        }
    }, [data]);

    useEffect(() => {
        if (selectedName === null || selectedName === '') {
            setSelectedName('class');
            setFilteredData(data);
        } else {
            try {
                const filtered = data.filter(record => record.name && record.name.split(' ')[0] === selectedName);
                setFilteredData(filtered.length > 0 ? filtered : data);
            } catch (error) {
                console.error('Error filtering data:', error);
                setFilteredData(data);
            }
        }
    }, [data, selectedName]);

    return (
        <div className="home-container">
            <div className="main-content">
                <div className="left-column">
                    <div className="role-selector">
                        {role.map((person, index) => (
                            <button
                                key={index}
                                onClick={() => handleNameClick(person.firstName)}
                                className={selectedName === person.firstName ? 'selected' : 'unselected'}
                            >
                                {person.firstName} {person.lastName}
                            </button>
                        ))}
                    </div>
                    <div className="term-box">
                        <label htmlFor="term-select" className="term-select-text"></label>
                        <div className="term-sub_text">(Multiple Select)</div>
                        <select
                            className="select-button-home"
                            id="term-select"
                            multiple
                            onChange={handleTermChange}
                            value={selectedTerms}
                        >
                            {terms.map(term => (
                                <option key={term.termNumber} value={term.termNumber}>
                                    Term {term.termNumber}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="table-container">
                    <div className="class-and-navigation">
                        <div className="class-selector">
                            <label>Select A Class:</label>
                            <ClassSelector
                                classes={classes}
                                onSelect={handleClassSelect}
                                selectedClass={selectedClass}
                            />
                        </div>
                        <div className="navigation-controls">
                        <span className="date-container">
                            {Array.isArray(currentDate)
                                ? `${currentDate[0].toDateString()} - ${currentDate[1].toDateString()}`
                                : currentDate.toDateString()}
                        </span>
                            <button onClick={() => setShowCalendar(!showCalendar)}>
                                {showCalendar ? 'Hide Calendar' : 'Show Calendar'}
                            </button>
                        </div>
                    </div>

                    {showCalendar && (
                        <div className="calendar-container">
                            <ThreeMonthCalendar
                                recordDates={recordDates}
                                onDateRangeChange={handleDateRangeChange}
                                dateRange={dateRange}
                            />
                        </div>
                    )}
                    {selectedClass && (
                        <div>
                            <div className="record-count">
                                Records for {displayName}: {`${(currentPage - 1) * 100 + 1} to ${Math.min(currentPage * 100, totalRecords)} of ${totalRecords}`}
                            </div>
                            {isLoading ? (
                                <div className="loader-container">
                                    <DNA
                                        visible={true}
                                        height="160"
                                        width="160"
                                        ariaLabel="dna-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="dna-wrapper"
                                    />
                                </div>
                            ) : (
                                <DataTable
                                    data={filteredData}
                                    onStateChange={handleStateChange}
                                    onEdit={(record) => handleEdit(selectedClassId, record)}
                                    onDelete={(record) => handleDelete(selectedClassId, record)}
                                    setData={setData}
                                    showEditModal={showEditModal}
                                    setShowEditModal={setShowEditModal}
                                    selectedRecord={selectedRecord}
                                    setSelectedRecord={setSelectedRecord}
                                    record={selectedRecord}
                                    onSave={handleSaveRecord}
                                />
                            )}
                            <div className="pagination-controls">
                                <button onClick={navigatePrevious} disabled={currentPage === 1}>Previous</button>
                                <button onClick={navigateNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    )}
                    <button onClick={handleExportCSV} className="export-button">Export CSV</button>
                </div>
            </div>
        </div>
    );
};

export default Home;
