import React, {useEffect, useState} from 'react';
import axios from 'axios';
import '../../css/Login.css'; // Reuse the CSS file for Login

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/forgot-password'
            });
        }
    }, []);

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/forgot-password`, { email });
            setMessage(response.data);
        } catch (error) {
            console.error('Forgot password error:', error);
            setMessage('Error sending password reset email');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2 className="login-title">Forgot Password</h2>
                <form onSubmit={handleForgotPassword} className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="login-input"
                    />
                    <button type="submit" className="login-button">Send Reset Link</button>
                </form>
                {message && <p className="reset-message">{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPassword;
