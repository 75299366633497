import React, { useState } from 'react';
import axios from 'axios';
import FeedbackModal from './FeedbackModal';
import '../../css/Feedback.css';

const Feedback = ({ position, buttonStyles, buttonText }) => {
    const [showButton, setShowButton] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));

    const handleButtonClick = () => {
        setShowButton(false);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setShowButton(true);
    };

    // Define the handleSubmit function
    const handleSubmit = async (feedbackData) => {
        try {
            // Post the feedback data to the backend
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/messages/feedback`, feedbackData);
            console.log('Feedback submitted successfully:', response.data);
            alert('Thank you for your feedback!');
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('An error occurred while submitting your feedback. Please try again later.');
        }
    };

    return (
        <>
            {showButton && (
                <button
                    style={{ ...buttonStyles, [position]: '20px' }}
                    onClick={handleButtonClick}
                    className="feedback-button"
                >
                    {buttonText || 'Leave Feedback'}
                </button>
            )}
            <FeedbackModal
                user={user}
                isOpen={isModalOpen}
                onSubmit={handleSubmit} // Pass handleSubmit as onSubmit prop
                onClose={handleCloseModal}
            />
        </>
    );
};

export default Feedback;
