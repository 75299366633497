import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/Reports.css';
import BehaviorGraph from './BehaviorGraph';
import BehaviorTimeGraph from './BehaviorTimeGraph'; // Import the new BehaviorTimeGraph component
import { Comment } from 'react-loader-spinner';
import SummaryReport from './SummaryReport'; // Import the new SummaryReport component

const Reports = () => {
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState('');
    const [reportData, setReportData] = useState(null);
    const [reportType, setReportType] = useState('summary');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingStudents, setLoadingStudents] = useState(false); // New state for loading students
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [terms, setTerms] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]); // Update to handle multiple terms


    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/reports'
            });
        }
    }, []);

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/classes`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setClasses(response.data);

                const storedClass = localStorage.getItem('selectedReportClass');
                if (storedClass) {
                    setSelectedClass(storedClass);
                    await fetchStudents(storedClass); // Fetch students for the stored class
                } else if (response.data.length > 0) {
                    setSelectedClass(response.data[0]._id);
                    localStorage.setItem('selectedReportClass', response.data[0]._id);
                    await fetchStudents(response.data[0]._id);
                }
            } catch (error) {
                console.error('Error fetching classes:', error);
            }
        };

        const fetchTerms = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/terms`);
                setTerms(response.data);

                const storedTerms = localStorage.getItem('selectedTerms');
                if (storedTerms) {
                    setSelectedTerms(JSON.parse(storedTerms));
                } else if (response.data.length > 0) {
                    setSelectedTerms([response.data[0].termNumber]);
                    localStorage.setItem('selectedTerms', JSON.stringify([response.data[0].termNumber]));
                }
            } catch (error) {
                console.error('Error fetching terms:', error);
            }
        };

        fetchClasses();
        fetchTerms();
    }, [BASE_URL]);

    useEffect(() => {
        if (selectedClass) {
            fetchStudents(selectedClass);
        }
    }, [selectedClass]);

    const fetchStudents = async (classId) => {
        setLoadingStudents(true); // Set loading state to true
        localStorage.setItem('selectedReportClass', classId);
        try {
            const response = await axios.get(`${BASE_URL}/classes/${classId}/students`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setStudents(response.data);
        } catch (error) {
            console.error('Error fetching students:', error);
        } finally {
            setLoadingStudents(false); // Set loading state to false
        }
    };

    useEffect(() => {
        const storedStudent = localStorage.getItem('selectedStudent');
        const storedReportType = localStorage.getItem('reportType');
        const storedStartDate = localStorage.getItem('startDate');
        const storedEndDate = localStorage.getItem('endDate');
        const storedReportData = localStorage.getItem('reportData');
        const storedSelectedClass = localStorage.getItem('selectedReportClass');

        if (storedSelectedClass) setSelectedClass(storedSelectedClass);
        if (storedStudent) setSelectedStudent(storedStudent);
        if (storedReportType) setReportType(storedReportType);
        if (storedStartDate) setStartDate(storedStartDate);
        if (storedEndDate) setEndDate(storedEndDate);
        if (storedReportData) setReportData(JSON.parse(storedReportData));
    }, []);

    useEffect(() => {
        if (selectedStudent) {
            localStorage.setItem('selectedStudent', selectedStudent);
        }
    }, [selectedStudent]);

    useEffect(() => {
        if (startDate) {
            localStorage.setItem('startDate', startDate);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) {
            localStorage.setItem('endDate', endDate);
        }
    }, [endDate]);

    useEffect(() => {
        if (reportType) {
            localStorage.setItem('reportType', reportType);
        }
    }, [reportType]);

    useEffect(() => {
        if (reportData) {
            localStorage.setItem('reportData', JSON.stringify(reportData));
        }
    }, [reportData]);

    const handleTermChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value, 10));
        setSelectedTerms(selectedOptions);

        const selectedTermObjects = selectedOptions.map(termNumber => terms.find(term => term.termNumber === termNumber));
        const startDates = selectedTermObjects.map(term => new Date(term.startDate));
        const endDates = selectedTermObjects.map(term => new Date(term.endDate));

        const overallStartDate = new Date(Math.min(...startDates));
        const overallEndDate = new Date(Math.max(...endDates));

        setStartDate(overallStartDate.toISOString().split('T')[0]);
        setEndDate(overallEndDate.toISOString().split('T')[0]);
    };

    const generateReport = async (reportOption = 'general') => {
        setReportData(null);
        setLoading(true);

        console.log('REPORT TYPE', reportType);

        try {
            let endpoint;
            if (reportType === 'behavior-time-graph') {
                endpoint = `${BASE_URL}/classes/${selectedClass}/records/times/${startDate}/${endDate}`;
            } else if (reportType === 'behavior-graph') {
                endpoint = `${BASE_URL}/classes/behavior/report/${selectedClass}/behavior-graph`;
            } else {
                endpoint = reportType === 'summary'
                    ? `${BASE_URL}/classes/${selectedClass}/reports/summary`
                    : `${BASE_URL}/classes/${selectedClass}/reports/${reportType}`;
            }

            const myStud = students.find(s => s._id === selectedStudent);
            if (!myStud) {
                console.error('Selected student not found.');
                return;
            }
            console.log('Selected Student:', myStud);

            const response = await axios({
                method: reportType === 'summary' ? 'POST' : 'GET',
                url: endpoint,
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                data: reportType === 'summary' ? {
                    studentId: selectedStudent,
                    studentName: myStud.firstName,
                    startDate: startDate,
                    endDate: endDate,
                    reportOption: reportOption
                } : {},
                params: reportType !== 'summary' ? {
                    studentId: selectedStudent,
                    studentName: myStud.firstName,
                    startDate: startDate,
                    endDate: endDate,
                } : {}
            });

            setReportData(response.data);
            localStorage.setItem('reportData', JSON.stringify(response.data));
        } catch (error) {
            console.error('Error generating report:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerateReport = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'generateReport',
                reportType: reportType,
                selectedClass: selectedClass,
                selectedStudent: selectedStudent,
                startDate: startDate,
                endDate: endDate
            });
        }
        if (reportType === 'summary') {
            const selectedOption = localStorage.getItem('selectedReportOption') || 'general';
            generateReport(selectedOption);
        } else {
            generateReport();
        }
    };

    return (
        <div className="reports-container fade-in">
            <h1>Reports</h1>
            <div className="reports-content">
                <div className="reports-controls">
                    <label>
                        Select Class:
                        <select
                            className="select-button"
                            value={selectedClass}
                            onChange={(e) => {
                                setSelectedClass(e.target.value);
                                fetchStudents(e.target.value);
                            }}
                        >
                            <option value="">Select a class</option>
                            {classes.map((cls) => (
                                <option key={cls._id} value={cls._id}>
                                    {cls.className}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        Start Date:
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    <label>
                        End Date:
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </label>
                    <div>
                        <label htmlFor="term-select">
                            Select Term:&nbsp;<span className="term-sub_text">(Multiple Select)</span>
                        </label>
                        <select
                            className="select-button"
                            id="term-select"
                            multiple
                            onChange={handleTermChange}
                            value={selectedTerms}
                        >
                            {terms.map(term => (
                                <option key={term.termNumber} value={term.termNumber}>
                                    Term {term.termNumber}
                                </option>
                            ))}
                        </select>
                    </div>
                    {selectedStudent && (
                        <div className="report-options">
                            <label>
                                Report Type:
                                <select
                                    className="select-button"
                                    value={reportType}
                                    onChange={(e) => setReportType(e.target.value)}
                                >
                                    <option value="behavior-graph">Overview Graph</option>
                                    <option value="behavior-time-graph">Report Times Graph</option>
                                    <option value="summary">Written Summary</option>
                                </select>
                            </label>
                        </div>
                    )}
                    {selectedClass && (
                        <div className="student-list">
                            <button onClick={handleGenerateReport}>Generate Report</button>
                            <h3>Select Student:</h3>
                            {loadingStudents ? (
                                <div className="loader-container">
                                    <div className="loader-wrapper">
                                        <Comment
                                            visible={true}
                                            height="260"
                                            width="260"
                                            ariaLabel="comment-loading"
                                            color="var(--secondary-color)"
                                            backgroundColor="var(--primary-color)"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <ul>
                                    {students.map((student) => (
                                        <li
                                            key={student._id}
                                            className={selectedStudent === student._id ? 'selected' : ''}
                                            onClick={() => {
                                                setSelectedStudent(student._id);
                                                setReportData(null);
                                            }}
                                        >
                                            {student.firstName} {student.lastName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}
                </div>
                <div className="report-data">
                    {loading && (
                        <div className="loader-container">
                            <div className="loader-wrapper">
                                <Comment
                                    visible={true}
                                    height="260"
                                    width="260"
                                    ariaLabel="comment-loading"
                                    color="var(--secondary-color)"
                                    backgroundColor="var(--primary-color)"
                                />
                            </div>
                        </div>
                    )}
                    {reportType === 'summary' && (
                        <SummaryReport
                            data={reportData || { summary: '' }}
                            generateReportWithType={generateReport}
                        />
                    )}
                    {reportType === 'behavior-graph' && reportData && (
                        <BehaviorGraph data={reportData} />
                    )}
                    {reportType === 'behavior-time-graph' && reportData && (
                        <BehaviorTimeGraph data={reportData} /> // Add the new component
                    )}
                </div>
            </div>
        </div>
    );
};

export default Reports;
