import React, { useState } from 'react';

const FeedbackModal = ({ user, onSubmit, isOpen, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [rating, setRating] = useState(1); // Add state for rating with initial value of 1
    const [feedbackType, setFeedbackType] = useState('Bug'); // Feedback type state
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            // Ensure all required fields are included in the feedback data
            await onSubmit({
                name: user.firstName,
                email: user.email,
                message: feedback, // Use 'message' instead of 'feedback'
                rating, // Include the rating state
                isBug: feedbackType === 'Bug', // Boolean for 'Bug' type
                isSuggestion: feedbackType === 'Request' // Boolean for 'Request' type
            });
            setFeedback(''); // Reset feedback state
            setRating(1); // Reset rating state
            setFeedbackType('Bug'); // Reset feedback type state
            onClose(); // Close the modal after successful submission
            alert('Thank you for your feedback!');
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('An error occurred while submitting your feedback. Please try again later.');
        }
    };

    if (!isOpen) return null; // If the modal is not open, return null

    return (
        <div className="feedback-modal-overlay">
            <div className="feedback-modal-content">
                <h2 className="leave-feedback-text">Leave Feedback</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Feedback Type Toggle */}
                    <div className="feedback-type-toggle">
                        <label className={feedbackType === 'Bug' ? 'active' : ''}>
                            <input
                                type="radio"
                                name="feedbackType"
                                value="Bug"
                                checked={feedbackType === 'Bug'}
                                onChange={(e) => setFeedbackType(e.target.value)}
                            />
                            Bug
                        </label>
                        <label className={feedbackType === 'Request' ? 'active' : ''}>
                            <input
                                type="radio"
                                name="feedbackType"
                                value="Request"
                                checked={feedbackType === 'Request'}
                                onChange={(e) => setFeedbackType(e.target.value)}
                            />
                            Request
                        </label>
                    </div>

                    <div>
                        <input
                            id="name"
                            type="text"
                            value={user.firstName}
                            disabled
                            className="feedback-input"
                        />
                    </div>
                    <div>
                        <input
                            id="email"
                            type="email"
                            value={user.email}
                            disabled
                            className="feedback-input"
                        />
                    </div>

                    {/* Rating Slider Field */}
                    <div className="rating-slider">
                        <label htmlFor="rating" className="slider-label">Severity of the Issue:</label>
                        <div className="slider-value-container">
                            <span className="slider-value">Small</span>
                            <input
                                type="range"
                                id="rating"
                                min="1"
                                max="5"
                                value={rating}
                                onChange={(e) => setRating(parseInt(e.target.value, 10))}
                                className="feedback-slider"
                            />
                            <span className="slider-value">Ouch!!</span>
                        </div>
                    </div>

                    <div>
                        <textarea
                            id="feedback"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            required
                            className="feedback-input"
                            rows="4"
                            placeholder="Enter your feedback here..."
                        />
                    </div>

                    {error && (
                        <div className="feedback-error" role="alert">
                            <p className="font-bold">Error</p>
                            <p>{error}</p>
                        </div>
                    )}

                    <div className="feedback-modal-buttons">
                        <button
                            type="button"
                            onClick={onClose}
                            className="cancel-button"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="submit-button"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FeedbackModal;
