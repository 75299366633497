export const setToken = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const clearToken = () => {
    localStorage.removeItem('token');
};

export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const logout = (navigate) => {
    clearToken();
    navigate('/login');
};

export const isAdmin = () => {
    // Example logic to check if the user is an admin
    const user = JSON.parse(localStorage.getItem("user"));
    return user && user.role === 'admin';
};
