import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, isAdmin } from '../auth';

const AdminProtectedRoute = ({ element }) => {
    if (!isAuthenticated()) {
        return <Navigate to="/login" />;
    }

    if (!isAdmin()) {
        return <Navigate to="/dash" />; // Redirect to the dashboard if not an admin
    }

    return element; // Use the 'element' prop directly
};

export default AdminProtectedRoute;