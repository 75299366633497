import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../../auth';
import '../../css/Login.css';  // Import the Login.css file

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [platform, setPlatform] = useState('Android'); // State for the platform toggle
    const navigate = useNavigate();

    useEffect(() => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'pageview',
                page: '/register'
            });
        }
    }, []);

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
                firstName, lastName, email, password, platform  // Include platform in the registration request
            });
            setToken(response.data.token);
            navigate('/dash');
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2 className="login-title">Register</h2>
                <form onSubmit={handleRegister} className="login-form">
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="login-input"
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="login-input"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="login-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="login-input"
                    />
                    {/*<div className="platform-toggle">*/}
                    {/*    <label>*/}
                    {/*        <input*/}
                    {/*            type="radio"*/}
                    {/*            name="platform"*/}
                    {/*            value="Android"*/}
                    {/*            checked={platform === 'Android'}*/}
                    {/*            onChange={(e) => setPlatform(e.target.value)}*/}
                    {/*        />*/}
                    {/*        Android*/}
                    {/*    </label>*/}
                    {/*    <label>*/}
                    {/*        <input*/}
                    {/*            type="radio"*/}
                    {/*            name="platform"*/}
                    {/*            value="iPhone"*/}
                    {/*            checked={platform === 'iPhone'}*/}
                    {/*            onChange={(e) => setPlatform(e.target.value)}*/}
                    {/*        />*/}
                    {/*        iPhone*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                    <button type="submit" className="login-button">Register</button>
                </form>
                <p className="login-message">Already have an account? <a href="/login" className="login-link">Login here</a></p>
            </div>
        </div>
    );
};

export default Register;
