import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Checkbox from '@mui/material/Checkbox';
import '../../css/DataTable.css';
import EditRecordModal from './EditRecordModal';
import ConfirmationModal from './ConfirmationModal';
import '@fortawesome/fontawesome-free/css/all.min.css';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const DraggableColumnHeader = ({ column, index, moveColumn, handleSort }) => {
    const ref = React.useRef(null);


    const [, drop] = useDrop({
        accept: 'column',
        hover(item, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

            moveColumn(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'column',
        item: () => ({ id: column.key, index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <th
            ref={ref}
            onClick={() => handleSort(column.key)}
            style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
            className="header-cell"
        >
            {column.label}
            {column.isSorted && (column.isSortedDesc ? ' 🔽' : ' 🔼')}
        </th>
    );
};

const DataTable = ({ data, setData, onStateChange, onDelete, onEdit, onSave, showEditModal, setShowEditModal, selectedRecord }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [columns, setColumns] = useState([
        { key: 'name', label: 'Name' },
        { key: 'topic', label: 'Topic' },
        { key: 'state', label: 'State' },
        { key: 'location', label: 'Location' },
        { key: 'date', label: 'Date' },
        { key: 'user', label: 'User' },
        { key: 'retroTime', label: 'Retro Time' },
        { key: 'comment', label: 'Comment' },
    ]);


    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };

    const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null); // Track the last selected row index

    const handleSelectRow = (id, index, shiftKey) => {
        console.log('Shift key:', shiftKey); // Log the shiftKey value
        if (shiftKey && lastSelectedRowIndex !== null) {
            // Handle shift-click to select multiple rows
            const start = Math.min(lastSelectedRowIndex, index);
            const end = Math.max(lastSelectedRowIndex, index);
            const newSelectedRows = data.slice(start, end + 1).map(row => row._id);

            setSelectedRows(prevSelectedRows => {
                const uniqueSelectedRows = [...new Set([...prevSelectedRows, ...newSelectedRows])];
                return uniqueSelectedRows;
            });
        } else {
            // Handle single click to select/deselect a row
            setSelectedRows(prevSelectedRows => {
                if (prevSelectedRows.includes(id)) {
                    return prevSelectedRows.filter(rowId => rowId !== id);
                } else {
                    return [...prevSelectedRows, id];
                }
            });
            setLastSelectedRowIndex(index); // Update the last selected row index
        }
    };


    const handleDeleteClick = (record) => {
        setDeleteType('single');
        setRecordToDelete(record);
        setShowDeleteConfirmation(true);
    };

    const handleBulkDeleteClick = () => {
        setDeleteType('bulk');
        setShowDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        if (deleteType === 'single' && recordToDelete) {
            onDelete(recordToDelete); // Call the existing single delete function
        } else if (deleteType === 'bulk') {
            try {
                // Send a request to the backend to delete multiple records
                const response = await fetch(`${BASE_URL}/records/bulk-delete`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({ recordIds: selectedRows }), // Send the IDs of the selected rows to the backend
                });

                if (response.ok) {
                    // If successful, remove the records from the UI
                    setData((prevData) => prevData.filter(record => !selectedRows.includes(record._id)));
                    setSelectedRows([]); // Clear the selected rows
                } else {
                    console.error('Failed to delete records');
                }
            } catch (error) {
                console.error('Error deleting records:', error);
            }
        }

        setShowDeleteConfirmation(false); // Close the confirmation modal
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = React.useMemo(() => {
        let sortableItems = [...data];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [data, sortConfig]);

    const moveColumn = useCallback((dragIndex, hoverIndex) => {
        setColumns((prevColumns) =>
            update(prevColumns, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevColumns[dragIndex]],
                ],
            })
        );
    }, []);

    useEffect(() => {
        onStateChange({ data: sortedData, sortConfig, columns });
    }, [sortedData, sortConfig, columns, onStateChange]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <div className="bulk-actions">
                    <button
                        onClick={handleBulkDeleteClick}
                        className="btn btn-danger"
                        disabled={selectedRows.length === 0}
                        style={{
                            opacity: selectedRows.length === 0 ? 0.5 : 1,
                            cursor: selectedRows.length === 0 ? 'not-allowed' : 'pointer'
                        }}
                    >
                        Delete Selected ({selectedRows.length})
                    </button>
                    <button
                        onClick={() => setSelectedRows([])}  // Clear selected rows
                        className="btn btn-secondary"
                        disabled={selectedRows.length === 0}
                        style={{
                            marginLeft: '10px',  // Add some space between the buttons
                            opacity: selectedRows.length === 0 ? 0.5 : 1,
                            cursor: selectedRows.length === 0 ? 'not-allowed' : 'pointer'
                        }}
                    >
                        Unselect All
                    </button>
                </div>
                <table className="data-table">
                    <thead>
                    <tr>
                        <th>Select</th>
                        {columns.map((column, index) => (
                            <DraggableColumnHeader
                                key={column.key}
                                column={{
                                    ...column,
                                    isSorted: sortConfig.key === column.key,
                                    isSortedDesc: sortConfig.direction === 'descending'
                                }}
                                index={index}
                                moveColumn={moveColumn}
                                handleSort={handleSort}
                            />
                        ))}
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((row, index) => (
                        <tr key={row._id}>
                            <td>
                                <Checkbox
                                    checked={selectedRows.includes(row._id)}
                                    onClick={(e) => handleSelectRow(row._id, index, e.shiftKey)} // Use onClick to capture shiftKey
                                    color="primary"
                                    inputProps={{ 'aria-label': 'Select row' }}
                                />
                            </td>
                            {columns.map((column) => (
                                <td key={column.key}>
                                    {column.key === 'date' || column.key === 'retroTime'
                                        ? formatDate(row[column.key])
                                        : column.key === 'user'
                                            ? row[column.key].split('@')[0]
                                            : row[column.key]}
                                </td>
                            ))}
                            <td>
                                <button onClick={() => onEdit(row)} className="icon-button">
                                    <i className="fas fa-edit"></i>
                                </button>
                                <button onClick={() => handleDeleteClick(row)} className="icon-button">
                                    <i className="fas fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {showEditModal && (
                    <EditRecordModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        record={selectedRecord}
                        onSave={onSave}
                    />
                )}
                <ConfirmationModal
                    open={showDeleteConfirmation}
                    onClose={() => setShowDeleteConfirmation(false)}
                    onConfirm={handleConfirmDelete}
                    title="Confirm Deletion"
                    message={`Are you sure you want to delete ${deleteType === 'bulk' ? 'these records' : 'this record'}? This action is unrecoverable.`}
                />
            </div>
        </DndProvider>
    );
};

export default DataTable;
