const announcementTemplate = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tammoo Announcement</title>
    <style>
        /* Add specific styles for the announcement template */
        body {
            font-family: Arial, sans-serif;
            background-color: #f0f0f0;
            margin: 0;
            padding: 0;
            display: flex; /* Add this */
            flex-direction: column; /* Add this */
            align-items: center; /* Center the content horizontally */
        }
        .container {
            max-width: 600px;
            margin: 20px auto;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            display: flex; /* Add this */
            flex-direction: column; /* Add this */
        }
        .header {
            background: #ff9800;
            color: #fff;
            text-align: center;
            padding: 20px;
            border-radius: 10px 10px 0 0;
        }
        .footer {
            background: #f1f1f1;
            color: #555;
            text-align: center;
            padding: 10px;
            border-radius: 0 0 10px 10px;
        }
    </style>
</head>
<body>
<div class="container">
    <div class="header">
        <h1>Tammoo Announcement</h1>
    </div>
    <div class="content">
        <h2>New Features Released!</h2>
        <p>We are excited to announce the release of new features for the Tammoo app.</p>
    </div>
    <div class="footer">
        <p>&copy; 2024 Tammoo. All rights reserved.</p>
    </div>
</div>
</body>
</html>
`;


export default announcementTemplate;
