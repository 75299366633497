import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/TimetableManager.css';

const TimetableManager = () => {
    const [timetables, setTimetables] = useState([]);
    const [newTimetable, setNewTimetable] = useState({ name: '', blocks: [{ period: '', startTime: '', endTime: '' }] });
    const [editingTimetable, setEditingTimetable] = useState(null);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        const fetchTimetables = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/timetables/all`);
                setTimetables(response.data);
            } catch (error) {
                console.error('Error fetching timetables:', error);
            }
        };

        fetchTimetables();
    }, []);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const blocks = [...newTimetable.blocks];
        blocks[index] = { ...blocks[index], [name]: value };
        setNewTimetable({ ...newTimetable, blocks });
    };

    const handleTimetableChange = (e) => {
        const { name, value } = e.target;
        setNewTimetable(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddBlock = () => {
        setNewTimetable(prevState => ({
            ...prevState,
            blocks: [...prevState.blocks, { period: '', startTime: '', endTime: '' }]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editingTimetable
                ? await axios.put(`${process.env.REACT_APP_BASE_URL}/timetables/update/${editingTimetable._id}`, newTimetable)
                : await axios.post(`${process.env.REACT_APP_BASE_URL}/timetables/new`, newTimetable);

            setTimetables(prevState => editingTimetable
                ? prevState.map(timetable => timetable._id === editingTimetable._id ? response.data : timetable)
                : [...prevState, response.data]
            );
            resetForm();
        } catch (error) {
            console.error('Error saving timetable:', error);
        }
    };

    const handleEdit = (timetable) => {
        setNewTimetable(timetable);
        setEditingTimetable(timetable);
        setShowForm(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this timetable?');
        if (confirmDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/timetables/delete/${id}`);
                setTimetables(prevState => prevState.filter(timetable => timetable._id !== id));
            } catch (error) {
                console.error('Error deleting timetable:', error);
            }
        }
    };

    const handleToggleForm = () => {
        if (showForm) {
            // If the form is already shown, this will act as the "Cancel" function
            resetForm();
            setShowForm(false);
        } else {
            // If the form is hidden, this will prepare for adding a new timetable
            resetForm();
            setShowForm(true);
        }
    };

    const resetForm = () => {
        setNewTimetable({ name: '', blocks: [{ period: '', startTime: '', endTime: '' }] });
        setEditingTimetable(null);
    };

    return (
        <div className="timetable-manager">
            <h1>Manage Timetables</h1>
            <button onClick={handleToggleForm} className="timetable-button">
                {showForm ? 'Cancel' : 'Add New Timetable'}
            </button>
            {showForm && (
                <form onSubmit={handleSubmit} className="form">
                    <div>
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={newTimetable.name}
                            onChange={handleTimetableChange}
                            required
                        />
                    </div>
                    {newTimetable.blocks.map((block, index) => (
                        <div key={index} className="block">
                            <div>
                                <label>Period</label>
                                <input
                                    type="text"
                                    name="period"
                                    value={block.period}
                                    onChange={(e) => handleInputChange(e, index)}
                                    required
                                />
                            </div>
                            <div>
                                <label>Start Time</label>
                                <input
                                    type="time"
                                    name="startTime"
                                    value={block.startTime}
                                    onChange={(e) => handleInputChange(e, index)}
                                    required
                                />
                            </div>
                            <div>
                                <label>End Time</label>
                                <input
                                    type="time"
                                    name="endTime"
                                    value={block.endTime}
                                    onChange={(e) => handleInputChange(e, index)}
                                    required
                                />
                            </div>
                        </div>
                    ))}
                    <button type="button" onClick={handleAddBlock} className="button secondary">Add Block</button>
                    <button type="submit" className="button primary">
                        {editingTimetable ? 'Update Timetable' : 'Add Timetable'}
                    </button>
                </form>
            )}
            <ul className="timetable-list">
                {timetables.map(timetable => (
                    <li key={timetable._id} className="timetable-item">
                        <span>{timetable.name}</span>
                        <button onClick={() => handleEdit(timetable)} className="timetable-button">Edit</button>
                        <button onClick={() => handleDelete(timetable._id)} className="timetable-button danger">Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TimetableManager;
