import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { setToken } from '../../auth';
import '../../css/Login.css';
import mascotImage from '../../assets/bg-guy.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const clearLocalSettings = () => {
        localStorage.removeItem('selectedClass');
        localStorage.removeItem('currentDate');
        localStorage.removeItem('dateRange');
        localStorage.removeItem('selectedName');
        localStorage.removeItem('selectedTerms');
        // Add any other local storage items you want to clear
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/auth/login`,
                { email, password }
            );
            console.log('Response Data:', response.data); // Log response data
            const { token, refreshToken, user } = response.data;

            const currentUser = JSON.parse(localStorage.getItem('user'));
            console.log('CURRENT OR NO ???', currentUser)
            if (!currentUser || currentUser.email !== user.email) {
                clearLocalSettings();
            }

            window.dataLayer.push({
                event: 'login',
                userId: email // You might want to use a more anonymous identifier
            });


            setToken(token, refreshToken, user);
            console.log('Saving User Locally', user); // Log user data
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/dash');
        } catch (error) {
            window.dataLayer.push({
                event: 'loginFailed',
                errorMessage: error.response?.data?.message || 'Unknown error'
            });
            console.error('Login error:', error);
            alert('Login failed. Please check your credentials and try again.');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={mascotImage} alt="Mascot" className="mascot-login" />
                <h2 className="login-title">Tammoo Manager: Login</h2>
                <form onSubmit={handleLogin} className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="login-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="login-input"
                    />
                    <button type="submit" className="login-button">Login</button>
                </form>
                <Link to="/forgot-password" className="login-link">Forgot Password?</Link>
            </div>
        </div>
    );
};

export default Login;
