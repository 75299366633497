const invitationTemplate = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tammoo Invitation</title>
    <style>
        /* General body styling */
        body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f7f7f7; /* Light gray background for the body */
            box-sizing: border-box; /* Ensure consistent box sizing */
        }

        /* Main container styling */
        .container {
            width: 100%;
            max-width: 600px;
            margin: 20px auto; /* Centered with top margin */
            background-color: #ffffff;
            border-radius: 10px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
            overflow: hidden;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        /* Header section styling */
        .header {
            background-color: #5C73F2; /* Different background color for header */
            color: #ffffff;
            text-align: center;
            padding: 20px;
            border-radius: 10px 10px 0 0;
            box-sizing: border-box;
        }

        /* Main content styling */
        .content {
            padding: 20px;
            line-height: 1.6; /* Improve readability */
            box-sizing: border-box;
        }

        /* Headings inside content */
        .content h2, .content h3 {
            color: #333;
            margin-top: 0; /* Align with content section */
        }

        /* Paragraphs inside content */
        .content p {
            color: #555; /* Slightly darker for readability */
            margin: 10px 0; /* Space between paragraphs */
        }

        /* Invitation specific content styling */
        .content .invitation-details {
            margin: 20px 0; /* Space above and below the details */
            padding: 15px;
            background-color: #f1f1f1; /* Light background for details */
            border-radius: 5px;
        }

        /* Footer section styling */
        .footer {
            background-color: #f7f7f7; /* Light gray to differentiate footer */
            color: #777777;
            text-align: center;
            padding: 10px;
            border-radius: 0 0 10px 10px;
            box-sizing: border-box;
        }

        /* Button styling */
        .button {
            width: 40%;
            display: inline-block;
            padding: 10px 20px;
            margin: 10px 5px;
            font-size: 16px;
            color: #ffffff;
            background-color: #5C73F2; /* Match header color */
            border: none;
            border-radius: 5px;
            text-decoration: none;
            text-align: center;
            box-sizing: border-box;
        }

        /* Additional text styling */
        .highlight {
            color: #5C73F2; /* Match header color for emphasis */
        }

        .subtext {
            font-size: 14px;
            color: #888;
            line-height: 1.4;
        }
    </style>
</head>
<body>
<div class="container">
    <div class="header">
        <h1>You are Invited!</h1>
    </div>
    <div class="content">
        <h2>Dear [Recipient's Name],</h2>
        <p>We are thrilled to invite you to our special event hosted by Tammoo. This is a wonderful opportunity to connect, learn, and share ideas with like-minded individuals.</p>
        
        <div class="invitation-details">
            <h3>Event Details:</h3>
            <p><strong>Date:</strong> [Event Date]</p>
            <p><strong>Time:</strong> [Event Time]</p>
            <p><strong>Location:</strong> [Event Location]</p>
            <p class="subtext">Please confirm your attendance by clicking on one of the buttons below:</p>
        </div>

        <div>
            <a href="https://www.tammoo.com/rsvp?response=accept" class="button">Accept</a>
            <a href="https://www.tammoo.com/rsvp?response=decline" class="button">Decline</a>
        </div>

        <p>We look forward to seeing you there!</p>
        <p>Best regards,<br>The Tammoo Team</p>
    </div>
    <div class="footer">
        <p>&copy; 2024 Tammoo. All rights reserved.</p>
        <p>Questions? Visit our <a href="https://www.tammoo.com" class="highlight">website</a> for more information.</p>
    </div>
</div>
</body>
</html>
`;

export default invitationTemplate;
